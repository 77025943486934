code {
  font-family: monospace;
  font-size: 14px;
  color: #333;
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 3px;
}

.home-content {
  margin-top: 10px;
}

.box {
  transition: box-shadow 0.3s;
}

.box:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

/* Create a container element for the banner */
.banner {
  width: 100vw;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(91, 86, 86);
  background-size: cover;
  background-position: center;
}

/* Style the text within the banner */
.banner h1 {
  font-weight: bold;
  font-size: 2.5em;
  padding: 10px 20px;
  text-align: center;
  color: white;
}

/* Create a container element for the banner */
.footer {
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(91, 86, 86);
  background-size: cover;
  background-position: center;
}

.footer span {
  font-size: 0.7em;
  padding: 10px 20px;
  text-align: center;
  color: white;
}

/* Responsiveness - adjust font size for smaller screens */
@media (max-width: 768px) {
  .banner h1 {
    font-size: 1.8em;
  }
}
