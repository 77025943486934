.App {
  font-family: sans-serif;
  line-height: 1.5;
}

h1,
h2,
h3,
h4 {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.2;
  color: darkslateblue;
}

h5 {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.2;
  color: darkslateblue;
  margin-top: 10px;
}

p {
  margin-top: 20px;
  color: dimgrey;
  margin-bottom: 10px;
  font-size: medium;
}

a {
  margin-top: 20px;
  color: rgb(91, 86, 86);
  font-size: medium;
  margin-bottom: 10px;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.maincontent li {
  margin-bottom: 20px;
}

.maincontent {
  text-align: justify;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.logo {
  width: 200px;
  height: 100px;
}

.postDetails {
  margin-top: 20px;
  border-left-style: solid;
  border-color: rgb(224, 223, 223);
}

pre {
  overflow: auto; /* Enable both horizontal and vertical scrollbars when content overflows */
  white-space: pre-wrap; /* Preserve whitespace and allow wrapping for long lines */
  padding: 15px; /* Add some padding for visual comfort */
  border: 1px solid #ccc; /* Add a subtle border */
  border-radius: 5px; /* Slightly round the corners */
  background-color: #a1a0a0; /* Light background color */
}